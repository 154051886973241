* {
    box-sizing: border-box;
}
a {
    cursor: pointer;
}
body {
    color: #222;
}
img {
    max-width: 100%;
}
main {
    padding: 10px;
    max-width: 960px;
    margin: 0 auto;
}
header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;
    align-items: center;
}
header a {
    text-decoration: none;
    color: inherit;
}
header a.logo {
    font-weight: bold;
    font-size: 1.5rem;
}
header nav {
    display: flex;
    gap: 15px;
}
div.post {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
}
@media screen and (min-width: 700px) {
    div.post{
        grid-template-columns: .9fr 1.1fr;
    }
}
div.post div.texts h2 {
    margin: 0;
    font-size: 1.7rem;
}

div.post div.texts a{
    text-decoration: none;
    color: inherit;
}
div.post p.info {
    margin: 6px 0;
    color: #888;
    font-size: .7rem;
    font-weight: bold;
    display: flex;
    gap: 10px;
}
div.post p.info a.author {
    color: #333;
}

div.post p.summary {
    margin: 10px 0;
    line-height: 1.4rem;
}

form.login, form.register {
    max-width: 400px;
    margin: 0 auto;
}

input {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    padding: 5px 7px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

button {
    cursor: pointer;
    width: 100%;
    display: block;
    background-color: #555;
    border: 0;
    color: #fff;
    border-radius: 5px;
    padding: 7px 0;
}

form.login h1, form.register h1 {
    text-align: center;
}

div.post-page div.image {
    max-height: 200px;
    display: flex;
    overflow: hidden;
}

div.post-page div.image img {
    object-fit: cover;
    object-fit: center center;
    width: 100%;
}

div.post-page a{
    color: #333;
    text-decoration: underline;
}

div.post-page h1{
    text-align: center;
    margin: 10px 0 5px;
}

div.post-page time{
    text-align: center;
    display: block;
    font-size: 0.7rem;
    color: #aaa;
}

div.post-page div.author {
    text-align: center;
    margin-bottom: 20px;
    font-size: .7rem;
    font-weight: bold;
}

div.post-page div.content p {
    line-height: 1.6rem;
}

div.edit-row {
    text-align: center;
    margin-bottom: 20px;
}
div.post-page a.edit-btn {
    background-color: #333;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: white;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
}

a svg {
    height: 20px;
}

/* Estilos para a tabela */
table {
    border-collapse: collapse;
    width: auto;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* Estilos para o título */
  table caption {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Estilos para a primeira coluna */
  th:first-child, td:first-child {
    width: 50%;
  }
  
  /* Estilos para as células das colunas */
  th, td {
    width: 25%;
  }
  
  /* Estilos para as linhas pares */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  